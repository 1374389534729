import React from 'react'
import styled from 'styled-components'

const WrapperModal = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    top: 0;
    z-index: 30;
    background: rgba(255,255,255,0.2);
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    border: 1px solid rgba(255,255,255,0.3);
    padding: 20px;
`

const ButtonClose = styled.div`
  .btn-close {
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .line-1 {
    border: 1px solid #000;
    rotate: 45deg;
    width: 20px;
    position: absolute;
  }

  .line-2 {
    border: 1px solid #000;
    rotate: -45deg;
    width: 20px;
    position: absolute;
  }
`

const Button = styled.div`
  width: 100%;
  height: 48px;
  background: transparent linear-gradient(259deg, #F8B34C 0%, #A605F2 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  padding: 30px;
  color: var(--font-primary);

  h3, p, div {
    color: var(--font-primary);
  }

  .card-content {
    padding: 20px;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;

    img {
      border-radius: 10px;
    }
  }
`

export default function NewsCardModal({ detailEvent, handleShowDetail }) {
  return (
    <WrapperModal>
      <div className='flex justify-between'>
        <ButtonClose>
          <div className='btn-close'>
            <button onClick={handleShowDetail}>
              <div className='line-1'></div>
              <div className='line-2'></div>
            </button>
          </div>
        </ButtonClose>
      </div>
      <Content>
        <div className='mx-auto'>
          <div className="relative block overflow-hidden card-content">
            <img src={`/${detailEvent.frontmatter.thumbnail}`} alt="" />
          </div>
          <h3 className="pt-5 font-bold">{detailEvent.frontmatter.title}</h3>
          <div className="font-bold">| {detailEvent.frontmatter.type}</div>
          <div
            className="mt-8 detail"
            dangerouslySetInnerHTML={{
              __html: detailEvent.html,
            }}
          >
          </div>
          <Button onClick={handleShowDetail} role='button'>Close</Button>
        </div>
      </Content>
    </WrapperModal>
  )
}